
import { Component, Prop, Vue } from "vue-property-decorator";
import DialogIdentify from "@/components/request/DialogIdentify.vue";
import RegisterForm from "@/components/forms/RegisterForm.vue";
import GuestForm from "@/components/forms/GuestForm.vue";
import GuestFormDT from "@/components/forms/GuestFormDT.vue";

@Component({
  components: {
    DialogIdentify,
    RegisterForm,
    GuestForm,
    GuestFormDT
  }
})
export default class IdentifyUser extends Vue {
  @Prop({ default: false }) readonly date_request!: boolean;
  dialog = true;
  back = false;
  auth_method = "";
  private identifyUser(method: string) {
    if (method === "log") {
      this.auth_method = "log";
      this.back = false;
    } else if (method === "register") {
      this.auth_method = "register";
      this.back = true;
    } else if (method === "guest") {
      this.auth_method = "guest";
      this.back = true;
    }
  }
  private mounted() {
    this.$gtag.event("Identificación ", {
      event_label: "Identificación",
      event_category: "test",
      value: 1
    });
    window.scroll(0, 0);
  }
  private backAction() {
    this.auth_method = "";
    this.dialog = true;
  }

  private actionBack() {
    this.$store.dispatch("setStateToOne");
  }

  private showError($event: string) {
    this.$q.notify({
      message: ("<div style='font-size:20px'>" +
        this.$t("error." + $event) +
        "</div>") as string,
      color: "principal",
      html: true,
      position: "top",
      timeout: 5000
    });
    this.dialog = true;
  }

  private loggedFromRegister() {
    this.auth_method = "log";
  }
  private get showGuestForm() {
    if (this.auth_method === "guest" || this.auth_method === "log") {
      return true;
    }
    return false;
  }
}
