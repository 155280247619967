
import { Component, Prop, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import store from "@/store";
interface loginData {
  document_type: string;
  document_id: string;
  password: string;
}

@Component
export default class LoginForm extends Mixins(FormValidator) {
  @Prop({ default: false }) readonly quick_login!: boolean;
  options: object[] = [
    { label: "DNI", value: "0" },
    { label: "RUT", value: "1" },
    { label: "Pasaporte", value: "2" }
  ];
  protected formData: loginData = {
    document_type: "1",
    document_id: "",
    password: ""
  };

  private btnRegister: Boolean = true;

  private credentials_found = true;
  private dialogValdiar = false;

  private mounted() {
    if (this.$route.name != "newrequest") {
      this.btnRegister = false;
    }
    this.formatRut();
    if (this.$route.query.timeout == "true") {
      this.$q.notify({
        message: this.$t("notification.timeout") as string,
        color: "principal",
        position: "top",
        timeout: 5000
      });
      this.$q.loading.hide();
    }
  }
  private loginAttemp() {
    if (this.formData.document_type == "") {
      this.$q.notify({
        message: "Debe ingresar un rut válido",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return;
    }
    if (this.formData.password == "") {
      this.$q.notify({
        message: "Debe ingresar una contraseña",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return;
    }
    if (this.formData.password.length < 6) {
      this.$q.notify({
        message: "El formato de la contraseña no es correcta",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return;
    } else {
      this.$q.loading.show();
      this.$axios
        .post("personas/login", {
          tipo_documento: Number(this.formData.document_type),
          rut: this.formData.document_id,
          password: this.formData.password
        })
        .then(response => {
          this.$store.dispatch("login", response.data);
          this.redirectIntended();
        })
        .catch(error => {
          if (
            error.response.data.error.message == "auth/wrong-password" ||
            error.response.data.error.message ==
              "auth/invalid-login-credentials"
          ) {
            this.$q.notify({
              message: "La contraseña ingresada no es correcta",
              color: "principal",
              position: "top",
              timeout: 5000
            });
          } else if (
            error.response.data.error.message == "persona_no_rut" ||
            error.response.data.error.message == "auth/user-not-found"
          ) {
            this.$q.notify({
              message: "Rut ingresado no se encuentra registrado.",
              color: "principal",
              position: "top",
              timeout: 5000
            });
          } else if (
            error.response.data.error.message == "auth/weak-password"
          ) {
            this.$q.notify({
              message: "La contraseña ingresada no es correcta.",
              color: "principal",
              position: "top",
              timeout: 5000
            });
          } else if (error.response.data.error.message == "no_validado") {
            this.dialogValdiar = true;
          } else {
            this.$q.notify({
              message: "Ha ocurrido un problema, inténtelo más tarde",
              color: "principal",
              position: "top",
              timeout: 5000
            });
          }
        })
        .finally(() => {
          this.$q.loading.hide();
        });
    }
  }

  private confirmReset(): boolean {
    this.$q.loading.show();
    this.$axios
      .patch("personas/recuperar/contrasena/request", {
        tipo_documento: Number(this.formData.document_type),
        rut: this.formData.document_id
      })
      .then(response => {
        this.dialogValdiar = false;
        this.$q.notify({
          message: this.$t("notification.email_reset_password") as string,
          color: "principal",
          position: "top",
          timeout: 7500
        });
      })
      .catch(error => {
        this.dialogValdiar = false;
        this.$q.notify({
          message: this.$t("notification.email_reset_error") as string,
          color: "principal",
          position: "top",
          timeout: 7500
        });
      })
      .finally(() => {
        this.$q.loading.hide();
      });
    return true;
  }

  private redirectIntended(): void {
    if (this.quick_login) {
      this.$emit("logged");
      return;
    }
    if (this.$route.query.redirect === "login") {
      return;
    }
    const intended: string =
      typeof this.$route.query.redirect === "string"
        ? this.$route.query.redirect
        : ("welcome" as const);
    this.$router.push({ name: intended });
  }

  private get isFormValid(): boolean {
    if (this.formData.document_id === "" || this.formData.password === "") {
      return false;
    } else {
      return true;
    }
  }

  private setCredentialsNotFound() {
    this.credentials_found = false;
    (this.$refs.form as any).validate();
  }

  private credentialsFound() {
    if (this.credentials_found) {
      return true;
    } else {
      return this.$t("validation.invalid_password");
    }
  }
  private routerGo(name: any) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          this.$q.notify({
            message: "Ha ocurrido un error",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        });
      }
    }
  }

  private test() {
    this.$q.loading.show();
    if (this.$route.name == "newrequest") {
      let ticket: any = {
        step: this.$store.state.ticket.step,
        service_id: this.$store.state.ticket.service.id,
        service_name: this.$store.state.ticket.service.name,
        service_description: this.$store.state.ticket.service.description
      };
      this.$store.dispatch("saveStep", ticket);
    }
    this.$axios("redirect-claveunica", {
      params: { flag: 1 }
    }).then(response => {
      const pattern: RegExp = /.*accounts\.claveunica\.gob\.cl.*/;

      if (!pattern.test(response.data)) {
        return;
      }

      window.location.href = response.data;
    }).finally(() => {
      this.$q.loading.hide();
    });
    //ESTO ES PA TESTEAR DESPUES COMENTA
    /*this.$axios('signin-claveunica', {
      params: {code: '123', state: '321'},
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(response => {
      window.location.href = response.request.responseURL;
      //window.location.href = response.request.responseURL;
    }).catch(e => {
    })*/
  }
}
