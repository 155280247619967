
import { Vue, PropSync, Component } from "vue-property-decorator";
import LoginForm from "@/components/forms/LoginForm.vue";
import StepTracker from "@/components/request/StepTracker.vue";

@Component({
  components: {
    LoginForm,
    StepTracker
  }
})
export default class DialogIdentify extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;

  private mounted() {
    if (this.$store.state.logged === true) {
      this.userIdentification("log");
    }
  }
  private userIdentification(method: string) {
    this.syncedDialog = false;
    this.$emit("identify", method);
  }

  private routerGo(name: any) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          this.$q.notify({
            message: "Ha ocurrido un error",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        });
      }
    }
  }

  private test(){
    this.$q.loading.show();
    this.$axios.get('redirect-claveunica', {
      params: {
        flag: 1
      }
    }).then((response) => {
      const pattern: RegExp = /.*accounts\.claveunica\.gob\.cl.*/;

      if (!pattern.test(response.data)) {
        return 
      } 

      window.location.href = response.data;
    }).finally(() => {
      this.$q.loading.hide();
    });
  }

}
